<template>
  <div class="introduce-h5" :style="{ background: background }">
    <div class="head-icon">
      <svg-icon :iconClass="icon" class="w-38 h-38"></svg-icon>
    </div>
    <div class="text">
      <slot name="text"></slot>
    </div>
    <div class="desc">
      <slot name="desc"></slot>
    </div>
    <div class="desc-btn">
      <slot name="btn"></slot>
    </div>
    <div>
      <slot name="model"></slot>
    </div>
    <div class="w-fill">
      <slot name="img"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    background: {
      type: String,
      default: '#fff'
    },
    icon: {
      type: String,
      default: '',
    }
  }
}
</script>

<style lang="scss" scoped>
.introduce-h5 {
  .head-icon {
    text-align: center;
    padding-top: 32px;
  }
  .text {
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    color: #202020;
    line-height: 36px;
    margin-top: 10px;
  }

  .desc {
    text-align: center;
    font-size: 14px;
    color: #202020;
    line-height: 24px;
    padding: 16px;
    padding-bottom: 0;
    opacity: .6;
  }
}
</style>