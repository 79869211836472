<template>
  <div class="h5-box">
    <div class="h5-head-box">
      <img src="../assets/images/h5/h5_bg.png" alt="" class="h5-bg">
      <div class="top">
        <img src="../assets/images/icon_h5_6.png" alt class="w-26 h-26" />
        <el-button class="w-80 bdr-16" size="small" @click="toHomeClick">学习台</el-button>
      </div>
      <div class="content">
        <div class="title">做笔记、背书、背单词<br>就用闪记卡</div>
        <div class="desc">
          闪记卡是一款将笔记、记忆和知识管理融合于一身的新型学习工具和教育工具，不只是记录，更重要的帮你吸收知识，闪记卡是通用型工具，不限于学英语、语文、计算机、法律、中西医等，在应试提分上有显著效果。
        </div>
        <div class="support">
          支持：Windows I MacOS I iOS I Android
        </div>
        <el-button type="primary" style="width: 164px;height: 44px;border-radius: 12px;" @click="downLoadApp">下载App
        </el-button>
      </div>
    </div>
    <img src="../assets/images/h5/h_image_a@2x.png" alt="">
    <ProductIntroduce icon="h5_suji">
      <template v-slot:text>
        一步速记<br>
        捕捉灵感、知识点、生词
      </template>
      <template v-slot:desc>
        打开闪记卡只需一步，就能完成记录，输入单词会自动补全释义和发音，输入灵感和知识点会自动选择卡片模板，速记卡片默认保存到闪记盒以便后续统一管理，这一切都十分省心！
      </template>
      <template v-slot:img>
        <img src="../assets/images/h5/h_image_b@2x.png" alt="">
      </template>
    </ProductIntroduce>
    <ProductIntroduce icon="h5_yiwang" background="#f7f7f7">
      <template v-slot:text>
        个性化记忆算法<br>
        有效记住单词、知识点<br>
        抵抗遗忘
      </template>
      <template v-slot:desc>
        人脑可不像计算机，拷贝一份就能记下来，想把课本知识放进脑子里，你需要通过对知识点重复的回忆才能往人脑里成功写入数据，记忆秘籍就是回忆+重复，闪记卡的闪卡和间隔重复算法，很好地为你解决了这个问题，用闪卡帮你回忆知识，用间隔算法重复安排复习，高效帮你把知识写进大脑。
      </template>
      <template v-slot:img>
        <img src="../assets/images/h5/h_image_c@2x.png" alt="">
      </template>
    </ProductIntroduce>
    <ProductIntroduce icon="h5_jiegou">
      <template v-slot:text>
        大纲闪卡<br>读书笔记结构化
      </template>
      <template v-slot:desc>
        在学习计算机、心理学等专业课时，零散的记录无法让我有体系地整理和回顾知识点，用闪记卡大纲功能，可以分章节、分层级地用闪卡记录知识，相比传统长篇笔记，变得更加灵活。
      </template>
      <template v-slot:img>
        <img src="../assets/images/h5/h_image_d@2x.png" alt="">
      </template>
    </ProductIntroduce>
    <ProductIntroduce icon="h5_guanli" background="#f7f7f7">
      <template v-slot:text>
        强大的管理功能<br>掌握程度、标记、标签、双链
      </template>
      <template v-slot:btn>
        <div class="display-flex mg-r-56" style="justify-content: flex-end;">
          <el-button type="primary" class="bdr-10">用文件夹和标签分类知识</el-button>
        </div>
        <el-button type="primary" class="bdr-10 mg-l-30 mg-t-12">用双链链接知识｜用标记分层知识</el-button>
        <div class="display-flex mg-r-30" style="justify-content: flex-end;">
          <el-button type="primary" class="bdr-10 mg-t-12">用掌握程度了解学习情况</el-button>
        </div>
        <div class=" justify-content-center mg-t-12">
          <el-button type="primary" class="bdr-10" style="background: #202020;color: #FFC532;border-color: #202020;">
            你还可以排序、批量移动、停学卡片</el-button>
        </div>
      </template>
      <template v-slot:img>
        <img src="../assets/images/h5/h_image_e@2x.png" alt="">
      </template>
    </ProductIntroduce>
    <ProductIntroduce icon="h5_chain">
      <template v-slot:text>
        卡牌双链<br>建立你的知识网络
      </template>
      <template v-slot:desc>
        卡片双链是点对点链接，是具体知识点链接到具体知识点，它有效避免了长篇笔记中有大量无关信息干扰的问题，使得链接的过程更加精准顺畅。
        建立个人知识网络的好处在于，我们是把知识点成组，成网。与此同时，知识存储单位也实现了从多到一的跨变，点牵起线，线带动面，记忆与提取信息也因此变得更加灵活和轻松。
        在编辑器输入@，就可以与其他卡片产生链接，在学习和浏览卡片的时候，还可以通过点击@处查看@和被@的卡片
      </template>
      <template v-slot:img>
        <img src="../assets/images/h5/h_image_f@2x.png" alt="">
      </template>
    </ProductIntroduce>
    <ProductIntroduce icon="h5_template" background="#f7f7f7">
      <template v-slot:text>
        丰富的闪卡模版<br>轻松应对不同知识
      </template>
      <template v-slot:desc>
        知识种类再多，闪记卡模版都能帮你Hold住，不管是灵感、笔记、单词、题库，统统都行，更强大的是，闪记卡还提供智能模版
      </template>
      <template v-slot:model>
        <div class="model-box">
          <div class="model-item" v-for="item in modelList" :key="item.id">
            <svg-icon :iconClass="item.icon" class="w-90 h-106 bdr-8"></svg-icon>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
        <div class="model-box mg-t-16 mg-b-30">
          <div class="model-item" v-for="item in modelList2" :key="item.id">
            <svg-icon :iconClass="item.icon" class="w-90 h-106 bdr-8"></svg-icon>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </template>
    </ProductIntroduce>
    <ProductIntroduce icon="h5_zhineng">
      <template v-slot:text>
        智能模版<br>根据内容自动选模版
      </template>
      <template v-slot:desc>
        在记录灵感、知识点、单词时，我们总是需要不同的模板。模板的来回切换是一个多操作的环节，这样琐碎的操作，是对心流状态的一种破坏，我们希望使用闪记卡的你，可以高度专注在记录和学习本身，而不会被繁琐的操作中断专注的状态。
        为此我们原创设计了智能模板功能，你不再需要来回切换模板，智能模板会识别你的内容情况，自动匹配相应模板，以下是智能模板的识别规则，闪记卡中会有常驻提示，你无需记忆哦。
      </template>
      <template v-slot:img>
        <img src="../assets/images/h5/h_image_g@2x.png" alt="">
      </template>
    </ProductIntroduce>
    <ProductIntroduce icon="h5_language" background="#f7f7f7">
      <template v-slot:text>
        智能单词卡、支持100+语言<br>制卡从未如此简单
      </template>
      <template v-slot:desc>
        史上最强单词卡，只需输入单词/短语，自动补全释义、发音，制卡效率N倍提升，支持100多种语言，无论英语、日语、德语、韩语、西班牙语……全支持。 神经网络级语音合成，拟真人发音，支持添加个人笔记、助记，一键用专业词典软件查词。
      </template>
      <template v-slot:img>
        <img src="../assets/images/h5/h_image_h@2x.png" alt="">
      </template>
    </ProductIntroduce>
    <ProductIntroduce icon="h5_editor">
      <template v-slot:text>
        功能完善的编辑器
      </template>
      <template v-slot:desc>
        支持基础文字排版、遮挡、荧光笔、图片、Markdown
      </template>
      <template v-slot:img>
        <img src="../assets/images/h5/h_image_i@2x.png" alt="">
      </template>
    </ProductIntroduce>
    <ProductIntroduce icon="h5_wechat" background="#f7f7f7">
      <template v-slot:text>
        微信聊天输入笔记<br>同步到闪记卡
      </template>
      <template v-slot:desc>
        微信无疑是当下每个人使用频率最高的应用，无论是在与人聊天，还是阅读订阅号、观看视频号，过程当中的每一点灵思与收获，你都可以直接在微信完成笔记的录入，不用打开闪记卡。
      </template>
      <template v-slot:img>
        <img src="../assets/images/h5/h_image_j@2x.png" alt="">
      </template>
    </ProductIntroduce>
    <ProductIntroduce icon="h5_picture" >
      <template v-slot:text>
        图片遮挡
      </template>
      <template v-slot:desc>
        可将图片快速插入、任意遮挡<br>
        1.添加图片，将图片添加至编辑器，2.选中图片，点<br>击标注遮挡，3.选择任意颜色的矩形或笔刷进行遮<br>挡，点击完成
      </template>
      <template v-slot:img>
        <img src="../assets/images/h5/h_image_l@2x.png" alt="">
      </template>
    </ProductIntroduce>
    <ProductIntroduce icon="h5_import" background="#f7f7f7">
      <template v-slot:text>
        强大的笔记导入功能<br>适配市面常见笔记、脑图产品
      </template>
      <template v-slot:desc>
        闪记卡拥有非常强大的导入功能，支持Anki、Excel、微信笔记、脑图、大纲笔记的批量导入，同时还支持利用字段分隔符和卡片分隔符在线批量制作卡片，制卡效率倍速提升。
        批量制卡或者导入卡片，如果你需要对某些内容进行挖空，你可以通过！！挖空内容！！双叹号进行挖空，导入时会自动识别。
      </template>
      <template v-slot:img>
        <img src="../assets/images/h5/h_image_k@2x.png" alt="">
      </template>
    </ProductIntroduce>
   
    <!-- <ProductIntroduce icon="h5_shop">
      <template v-slot:text>
        惊叹商店<br>100000+闪卡资源<br>覆盖所有学科
      </template>
      <template v-slot:desc>
        在商店你可以下载现成的闪卡资源，快速开始学习，节约你的制卡时间，目前涵盖考研、考公、自考、大学、高中、初中、小学、单词等资源。你也可以将自己制作的闪卡分享到商店，帮助更多人学会用闪卡学习。
      </template>
      <template v-slot:img>
        <img src="../assets/images/h5/h_image_m@2x.png" alt="">
      </template>
    </ProductIntroduce> -->
    <div class="footer-bottom">
      <div class="desc">闪记卡 | 惊叹笔记</div>
      <div class="desc mg-b-10">深圳市惊叹科技有限公司 <a style="text-decoration: none;color:rgba(32, 32, 32, 0.4)" target="_blank"
          href="https://beian.miit.gov.cn/">{{ beian }}</a>
      </div>
      <div class="display-flex justify-content-center mg-t-5 fs-12 desc">
        <div class="mg-r-20">版本：2.0.0</div>
        <div @click="checkPrivacy" class="cursor-pointer ">隐私：点击查看</div>
      </div>
      <div class="mg-t-5 text-align-center desc">
        <div class="text-align-center position-relative " style="left:-8px">权限：允许应用拥有网络访问权限｜允许应用读取SD卡的内容</div>
      </div>
    </div>
  </div>
</template>
<script>
import { isMobile } from "../libs/util"
import util from '@/libs/util';

import ProductIntroduce from "./h5/ProductIntroduce.vue"
export default {
  components: {
    ProductIntroduce
  },
  data() {
    return {
      beian: "粤ICP备2022002259号",
      modelList: [
        {
          name: '笔记卡',
          icon: 'model_basic',
          id: 1
        },
        {
          name: '填空卡',
          icon: 'model_tiankong',
          id: 2
        }, {
          name: '选择题卡',
          icon: 'model_choice',
          id: 3
        },
      ],
      modelList2: [
        {
          name: '单词卡',
          icon: 'model_word',
          id: 3
        }, {
          name: '挖空卡',
          icon: 'model_wakong',
          id: 6
        }, {
          name: '智能模版',
          icon: 'model_zhineng',
          id: 7
        },
      ]
    }
  },
  methods: {
    downLoadApp() {
      this.$router.push('/preDownload')
    },
    enterToStudy() {
      this.$router.push('/home')
    },
     checkPrivacy() {
      location.href = 'https://www.amazingmemo.com/privacy.html'
    },
    toHomeClick() {
      this.$router.push('/home/studyPlatform/cardAll')
    },
  },
  mounted() {
    if (isMobile() !== 1) {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.h5-box {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;

  .h5-head-box {
    position: relative;

    .h5-bg {
      position: absolute;
      top: 16px;
      left: 0;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 44px;
      padding: 0 16px;
    }

    .content {
      text-align: center;
      padding: 0 16px;
      margin-top: 44px;

      .title {
        font-size: 32px;
        font-weight: 600;
        color: #202020;
        position: relative;
        z-index: 9;
        line-height: 46px;
      }

      .desc {
        font-size: 12px;
        color: rgba(32, 32, 32, 0.8000);
        line-height: 22px;
        margin-top: 20px;
      }

      .support {
        font-size: 12px;
        color: rgba(32, 32, 32, 0.8000);
        line-height: 17px;
        margin: 22px 0;
      }
    }
  }

  .footer-bottom {
    // height: 80px;

    padding: 22px 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .desc {
      font-size: 12px;
      color: rgba(32, 32, 32, 0.4000);
      line-height: 18px;
    }
  }

  .model-box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 17px;

    .model-item {
      border-radius: 20px;

      .name {
        text-align: center;
        margin-top: 9px;
        font-size: 14px;
        color: #202020;
        line-height: 17px;
        opacity: .6;
      }
    }
  }
}
</style>
Í